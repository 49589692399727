// @ts-nocheck
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { setOraiPrice, setAiriPrice, setBnbPrice, setKwtPrice, setMilkyPrice } from "./store/slices/priceSlice";
import { setBalanceKwt, setBalanceBnb, setBalanceMilky, setBalanceKWT } from "./store/slices/balanceSlice";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CacheSwitch } from "react-router-cache-route";
import { Links } from "src/components/common/Header/NavLinks";
import Header from "src/components/common/Header/Header";
import "src/App.css";
import { Provider, useDispatch } from "react-redux";
import store from "src/store/store";
import { dispatchRef } from "./store/ReduxDispatcher";
import axios from "axios";
import { getAiriBalance, getKwtBalance, getEthBalance, getMilkyBalance } from "src/services/web3";
import { useWeb3React } from "@web3-react/core";
import LoadingPage from "src/components/common/LoadingPage/LoadingPage";
import web3 from "web3";
import Tabs from "src/components/common/Tabs/Tabs";
import { getChainId, KAWAIIVERSE_CHAIN_ID, BSC_CHAIN_ID, PRICE_MARKET_ENDPOINT } from "./consts/blockchain";
import { randomDomain } from "./consts/blockchain";
import { RPC_URLS } from "./utils/connectors";

const MarketPlace = React.lazy(() => import("src/components/pages/Marketplace/MarketPlace"));
const NFTDetail = React.lazy(() => import("src/components/pages/NFTDetail"));
const Profile = React.lazy(() => import("src/components/pages/Profile"));
const NFT = React.lazy(() => import("src/components/pages/Profile/NFT/NFT"));
const Auction = React.lazy(() => import("src/components/pages/AuctionDetail"));
const Airdrop = React.lazy(() => import("src/components/pages/Airdrop"));
const Dashboard = React.lazy(() => import("src/components/pages/Dashboard"));
const Burn = React.lazy(() => import("src/components/pages/Burn"));
const Farming = React.lazy(() => import("src/components/pages/Farming"));
const BoostFarmingV2 = React.lazy(() => import("src/components/pages/BoostFarmingV2"));
const BoostFarming = React.lazy(() => import("src/components/pages/BoostFarming"));

const Reward = React.lazy(() => import("src/components/pages/Reward"));
const RevenueShare = React.lazy(() => import("src/components/pages/RevenueShare"));
const RevenueShareV2 = React.lazy(() => import("src/components/pages/RevenueShareV2"));
const RevenueShareV3 = React.lazy(() => import("src/components/pages/RevenueShareV3"));
const AirdropQuiz = React.lazy(() => import("src/components/pages/AirdropQuiz"));
const RevenueShareAdmin = React.lazy(() => import("src/components/pages/Admin"));
const BondDetail = React.lazy(() => import("src/components/pages/BondDetail"));
const BondStake = React.lazy(() => import("src/components/pages/Stake"));
const ListBonds = React.lazy(() => import("src/components/pages/ListBonds"));
const HalloweenAirdrop = React.lazy(() => import("src/components/pages/HalloweenAirdrop"));
const AirdropV3 = React.lazy(() => import("src/components/pages/AirdropV3"));
const AirdropV4 = React.lazy(() => import("src/components/pages/AirdropV4"));
const BuyBackHistory = React.lazy(() => import("src/components/pages/BuyBackHistory"));
const ClaimReward = React.lazy(() => import("src/components/pages/ClaimReward"));
const ClaimRewardV2 = React.lazy(() => import("src/components/pages/ClaimRewardV2"));
const AirdropOrai = React.lazy(() => import("src/components/pages/AirdropV5"));
const ListBundles = React.lazy(() => import("src/components/pages/Bundles/ListBundles"));
const BundleDetail = React.lazy(() => import("src/components/pages/Bundles/BundleDetail"));
const AirdropIslands = React.lazy(() => import("src/components/pages/AirdropIslands"));
const Register = React.lazy(() => import("src/components/pages/Register"));
const StakingKawaii = React.lazy(() => import("src/components/pages/StakingKawaii"));
const Vote = React.lazy(() => import("src/components/pages/Vote"));
const VoteDecor = React.lazy(() => import("src/components/pages/VoteDecor"));
const VoteResult = React.lazy(() => import("src/components/pages/VoteDecor/VoteResult"));
const ClaimAnniversary = React.lazy(() => import("src/components/pages/AirdropFirstAnniversary"));
const RewardAnniversary = React.lazy(() => import("src/components/pages/AirdropFirstAnniversary/Reward"));
const FinalVoteResult = React.lazy(() => import("src/components/pages/VoteDecor/FinalVoteResult"));
const ConnectDiscord = React.lazy(() => import("src/components/pages/ConnectDiscordCallback"));
const MilkyRefund = React.lazy(() => import("src/components/pages/MilkyRefund"));
const NFTNurtureDetail = React.lazy(() => import("src/components/pages/NFTNurtureDetail"));

const persistor = persistStore(store);
// scroll to top

const ScrollToTop: React.FC<{}> = ({ children }) => {
    const { pathname } = useLocation();
    const { action } = useHistory();
    useEffect(() => {
        if (action === "PUSH") {
            document.querySelector("#root")?.scrollTo(0, 0);
        }
    }, [pathname]);
    const link = Links.find((item) => item.path === pathname);
    if (link) {
        document.title = link.description ?? link.title;
    }
    return <Suspense fallback={<LoadingPage />}>{children}</Suspense>;
};

const UpdatePrice = () => {
    const dispatch = useDispatch();
    const updatePrice = async () => {
        try {
            const priceData = await axios.get(`${PRICE_MARKET_ENDPOINT}/simple/price?ids=airight,binancecoin,kawaii-islands,milky-token,oraichain-token&vs_currencies=usd`);

            dispatch(setAiriPrice(priceData?.data?.airight?.usd));
            dispatch(setBnbPrice(priceData?.data?.binancecoin?.usd));
            dispatch(setKwtPrice(priceData?.data['kawaii-islands']?.usd));
            dispatch(setMilkyPrice(priceData?.data['milky-token']?.usd));
            dispatch(setOraiPrice(priceData?.data['oraichain-token']?.usd));
        } catch (error) {}
    };

    useEffect(() => {
        updatePrice();
        const interval = setInterval(updatePrice, 1000 * 60);
        return clearInterval(interval);
    }, []);

    return null;
};

const UpdateBalance = () => {
    const { account, chainId } = useWeb3React();
    const dispatch = useDispatch();
    const updateBalance = async () => {
        try {
            const CHAIN_ID = getChainId(chainId);
            const [bnb, kwt, milky] = await Promise.all([
                getEthBalance(account, CHAIN_ID),
                getKwtBalance(account, CHAIN_ID),
                getMilkyBalance(account, CHAIN_ID),
            ]);

            const [kwtBsc, kwtKawaiiverse] = await Promise.all([
                getKwtBalance(account, BSC_CHAIN_ID),
                getKwtBalance(account, KAWAIIVERSE_CHAIN_ID),
            ]);

            dispatch(setBalanceBnb(Number(web3.utils.fromWei(bnb))));
            dispatch(setBalanceKwt(Number(web3.utils.fromWei(kwt))));
            dispatch(setBalanceMilky(Number(web3.utils.fromWei(milky))));
            dispatch(
                setBalanceKWT({
                    [BSC_CHAIN_ID]: Number(web3.utils.fromWei(kwtBsc)),
                    [KAWAIIVERSE_CHAIN_ID]: Number(web3.utils.fromWei(kwtKawaiiverse)),
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (account) {
            updateBalance();
        }
    }, [account, chainId]);

    return null;
};

const App: React.FC<{}> = ({}) => {
    const lightTheme = createTheme({
        palette: {
            type: "light",
        },
    });

    useEffect(() => {
        dispatchRef.current = store?.dispatch;
        const rpc = randomDomain(RPC_URLS[KAWAIIVERSE_CHAIN_ID]);
        localStorage.setItem("rpc", rpc);
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <UpdatePrice />
                <UpdateBalance />
                <ThemeProvider theme={lightTheme}>
                    <Router>
                        <Header />
                        <Tabs />
                        <ScrollToTop>
                            <Suspense fallback={<LoadingPage />}>
                                <Switch>
                                    <Route exact path="/milky-refund" component={MilkyRefund} />
                                    <Route exact path="/nft-nurture/:id" component={NFTNurtureDetail} />
                                    <Route exact path="/connect-discord-callback" component={ConnectDiscord} />
                                    <Route exact path="/golden-box/reward" component={RewardAnniversary} />
                                    <Route exact path="/golden-box" component={ClaimAnniversary} />
                                    <Route exact path="/decor-contest" component={FinalVoteResult} />
                                    <Route exact path="/kawaiiverse-hometown" component={AirdropIslands} />
                                    <Route exact path="/register" component={Register} />
                                    <Route exact path="/airdrop-samorai" component={AirdropOrai} />
                                    <Route exact path="/league-reward" component={ClaimReward} />
                                    <Route exact path="/league-reward-v2" component={ClaimRewardV2} />
                                    <Route exact path="/game-pool" component={BuyBackHistory} />
                                    <Route exact path="/nft/:id" component={NFTDetail} />
                                    <Route exact path="/profile/nft/:id/:chainId" component={NFT} />
                                    <Route exact path="/auction/:id?/:index?/:chainID?" component={Auction} />
                                    <Route exact path="/profile/:tab/:subTab?" component={Profile} />
                                    <Route exact path="/airdrop" component={Airdrop} />
                                    <Route exact path="/airdrop-quiz" component={AirdropQuiz} />
                                    <Route exact path="/airdrop-polkastarter" component={AirdropV4} />
                                    <Route exact path="/nft-farming" component={Farming} />
                                    <Route exact path="/referral" component={Reward} />
                                    <Route exact path="/boost-farming-v2" component={BoostFarmingV2} />
                                    <Route exact path="/boost-farming" component={BoostFarming} />
                                    <Route exact path="/reward-vote" component={Vote} />
                                    <Route exact path="/staking" component={StakingKawaii} />
                                    <Route exact path="/revenue-share" component={RevenueShare} />
                                    <Route exact path="/revenue-share-v2" component={RevenueShareV2} />
                                    <Route exact path="/revenue-share-v3" component={RevenueShareV3} />
                                    <Route exact path="/admin-revenue-share" component={RevenueShareAdmin} />
                                    <Route exact path="/halloween-airdrop" component={HalloweenAirdrop} />
                                    <Route exact path="/airdrop-oraichain" component={AirdropV3} />
                                    {/* <Route exact path="/reward-merkle" component={RewardMerkle} /> */}
                                    <Route exact path="/marketplace">
                                        <Redirect to="/fields" />
                                    </Route>
                                    <Route exact path="/burn" component={Burn} />
                                    <Route exact path="/bonding/:name/unstake" component={BondStake} />
                                    <Route exact path="/bonding/:name" component={BondDetail} />
                                    <Route exact path="/bonding" component={ListBonds} />
                                    <Route exact path="/bundles" component={ListBundles} />
                                    <Route exact path="/bundle/:auctionIndex/:chainID" component={BundleDetail} />
                                    <Route exact path="/:category" component={MarketPlace} />
                                    <Route exact path="/" component={Dashboard} />
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                        {/* <Footer /> */}
                    </Router>
                    <ToastContainer />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
